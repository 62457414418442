
import { defineComponent, ref } from "vue";
import {
  esimDirectoriesModalsInterface,
  esimDirectoriesTitleArray,
  esimDirectoriesTitles,
  esimDirectoriesTypes
} from "@/hooks/esim/directories/types/esimDirectories.types";
import { useEsimDirectories } from "@/hooks/esim/directories/useEsimDirectories";
import AppToolbarTable from "@/components/Table/ts/AppToolbarTable.vue";
import AppTableController from "@/components/Table/ts/AppTableController.vue";
import PageMainHeading from "@/components/Layout/PageMainHeading.vue";
import EsimDirectoryDialogsController from "@/components/Packages/eSIM/Directory/EsimDirectoryDialogsController.vue";
import { mapActions } from "vuex";
import { IEsimDirectoryDefault } from "@/api/interfaces/esim-directory/common";
import { log } from "~~/echarts/types/src/util/log";

interface editData {
  id: number;
  active: boolean;
  title: string;
}

interface dataState {
  id: number | null;
  networkGenerationModalOpened: boolean;
  tabs: string[];
  type: string;
  selectedTab: number;
  activeModalTitle: string;
  activeButtonText: string;
  modalTitle: string;
  editData: IEsimDirectoryDefault;
  modals: esimDirectoriesModalsInterface;
}

const initialValue = () => ({
  id: null,
  modalTitle: "",
  type: "",
  tabs: esimDirectoriesTitleArray,
  selectedTab: 0,
  activeModalTitle: "",
  activeButtonText: "",
  editData: {},
  modals: {
    directoryModal: false,
    validityPeriodModal: false,
    trafficAmountModal: false,
    plansModal: false,
    deleteModal: false
  }
});
export default defineComponent({
  name: "EsimDirectory",
  components: {
    AppToolbarTable,
    AppTableController,
    PageMainHeading,
    EsimDirectoryDialogsController
  },
  setup() {
    const data = useEsimDirectories(ref(esimDirectoriesTypes.validityPeriod));
    const { fetchData } = data;
    fetchData(esimDirectoriesTypes.dataTypes);
    return {
      ...data
    };
  },
  data(): dataState {
    console.log(esimDirectoriesTitleArray);
    return initialValue() as dataState;
  },
  methods: {
    ...mapActions([
      "showErrorNotification" //also supports payload `this.nameOfAction(amount)`
    ]),
    startEditing(id: number) {
      this.editData = this.currentData.find(
        ({ id: dataId }: { id: number }) => id == dataId
      );
      this.id = id;
      this.toogleModal(this.id, true);
    },
    toogleModal(id: number, edit = false) {
      if (this.modals[`${this.directoryType}Modal`] === undefined) {
        this.type = esimDirectoriesTitles[this.directoryType];
        this.modals.directoryModal = true;
      } else {
        this.modals[`${this.directoryType}Modal`] = true as boolean;
      }
      this.modalTitle =
        (edit ? "Edit " : "Add ") + esimDirectoriesTitles[this.directoryType];
    },
    cleanData() {
      Object.assign(this.$data, initialValue());
    },
    removeItemOpener(id: number) {
      this.editData = this.currentData.find(
        ({ id: dataId }: { id: number }) => id == dataId
      );
      this.id = this.editData.id;
      if (!!this.editData?.packages?.length) {
        this.showErrorNotification("This case is not empty");
        this.cleanData();
      } else {
        this.modals.deleteModal = true;
      }
    },
    onSubmit(data: IEsimDirectoryDefault, id: number) {
      this.cleanData();
      this.submitHandler(data, id);
    }
  }
});
